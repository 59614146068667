<template>
  <div>
    <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
      <img alt="example" style="width: 100%" :src="previewUrl"/>
    </a-modal>
    <a-card :bordered="false" class="ant-card-0">
      <!-- 操作 -->
      <div class="table-operations-none">
        <table-setting
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        :showHeader=false
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
         <span slot="expandedRowRender" slot-scope="text">
          <CategoryThreeList ref="subList" :id="text.id" :level="text.level"/>
        </span>
        <template slot="categoryPic" slot-scope="text, record">
          <div>
            <img v-if="record.categoryPic" :src="record.categoryPic" style="width:60px;height:62px;"
                 @click="handleRecordPreview(record.categoryPic)" alt="none"/>
          </div>
        </template>
        <span slot="isVisible" slot-scope="text, record">
          <a-switch @change="(checked) => onVisibleChange(checked,record)" :checked-children="$t('通用.文本.是')"
                    :un-checked-children="$t('通用.文本.否')" :checked="record.isVisible"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['shop:shop/category:edit']"/>
          <a @click="$refs.createForm.handleUpdate(record, undefined)" >
             <a-icon type="edit"/>{{ $t('通用.按钮.修改') }}
          </a>
          <a-divider type="vertical" v-hasPermi="['shop:shop/category:remove']"/>
          <a @click="handleDelete(record)" >
            <a-icon type="delete"/>{{ $t('通用.按钮.删除') }}
          </a>
        </span>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import {listShopCategory, delShopCategory, updateVisible} from '@/api/shop/shopCategory'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import CreateForm from './modules/CreateForm'
import CategoryThreeList from './threeList'
import {tableMixin} from '@/store/table-mixin'
import bus from "@/utils/bus";

export default {
  name: 'ShopCategory',
  components: {
    CustomDictTag,
    CategoryThreeList,
    CreateForm
  },
  props: {
    level: {
      type: Number,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      expandedKeys: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        categoryName: undefined,
        categoryPic: undefined,
        pid: undefined,
        level: 1,
        isVisible: undefined,
        isFilter: undefined,
        isHomeVisible: undefined,
        sort: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('店铺分类.分类名称'),
          dataIndex: 'categoryName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('店铺分类.分类图片'),
          dataIndex: 'categoryPic',
          scopedSlots: { customRender: 'categoryPic' },
          align: 'center'
        },
        {
          title: this.$t('店铺分类.是否显示'),
          dataIndex: 'isVisible',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'isVisible' },
        },
        {
          title: this.$t('店铺分类.创建时间'),
          dataIndex: 'createTime',
          scopedSlots: {customRender: 'createTime'},
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    /**编辑页面接收到更新的数据 */
    bus.$on('updateCateChildrenList', () => {
      this.getList()
    })
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  methods: {
    /** 查询店铺分类列表 */
    getList() {
      this.loading = true
      let query = {}
      query.pid = this.id
      query.level=this.level+1
      listShopCategory(query).then(response => {
        this.list = response.data
        this.loading = false
      })

      bus.$emit('updateThreeChildrenList')
    },
    onExpandCurrent(expandedKeys, row) {
      if (expandedKeys) {
        this.expandedKeys = [row.id]
      } else {
        this.expandedKeys = []
      }
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    onVisibleChange(checked, record) {
      let data = {id:record.id, isVisible: checked}
      updateVisible(data).then(response => {
        if (response.success) {
          this.$message.success(
            this.$t('通用.文本.修改成功'),
            3
          );
          this.$emit('ok')
          record.isVisible=checked
        }
      }).finally(() => {
        this.submitLoading = false
      })
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        categoryName: undefined,
        categoryPic: undefined,
        pid: undefined,
        level: undefined,
        isVisible: undefined,
        isFilter: undefined,
        isHomeVisible: undefined,
        sort: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },

    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk() {
          let data = []
          that.ids.map(id => data.push({"id": id}))
          return delShopCategory(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              const message = that.$t('通用.文本.删除成功')
              that.$message.success(
                message,
                3
              )
            })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    }
  }
}
</script>
<style>
.table-operations-none {
  display: none;
}

.ant-card-0 > .ant-card-body {
  padding: 0px;
}

</style>
